body {
  font-family: 'Roboto', sans-serif;
  color: #212121;
  overflow-x: hidden;
  padding-top: 70px;
}
@media (max-width: 767px) {
  body {
    padding-top: 0px;
  }
}
a:active,
a:focus {
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
.container {
  position: relative;
}
.p-relative {
  position: relative;
}
.full-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.bg-full-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.p-btm-46 {
  padding-bottom: 46%;
}
.no-padd {
  padding: 0;
  margin: 0;
}
.no-padd .col-xs-1,
.no-padd .col-sm-1,
.no-padd .col-md-1,
.no-padd .col-lg-1,
.no-padd .col-xs-2,
.no-padd .col-sm-2,
.no-padd .col-md-2,
.no-padd .col-lg-2,
.no-padd .col-xs-3,
.no-padd .col-sm-3,
.no-padd .col-md-3,
.no-padd .col-lg-3,
.no-padd .col-xs-4,
.no-padd .col-sm-4,
.no-padd .col-md-4,
.no-padd .col-lg-4,
.no-padd .col-xs-5,
.no-padd .col-sm-5,
.no-padd .col-md-5,
.no-padd .col-lg-5,
.no-padd .col-xs-6,
.no-padd .col-sm-6,
.no-padd .col-md-6,
.no-padd .col-lg-6,
.no-padd .col-xs-7,
.no-padd .col-sm-7,
.no-padd .col-md-7,
.no-padd .col-lg-7,
.no-padd .col-xs-8,
.no-padd .col-sm-8,
.no-padd .col-md-8,
.no-padd .col-lg-8,
.no-padd .col-xs-9,
.no-padd .col-sm-9,
.no-padd .col-md-9,
.no-padd .col-lg-9,
.no-padd .col-xs-10,
.no-padd .col-sm-10,
.no-padd .col-md-10,
.no-padd .col-lg-10,
.no-padd .col-xs-11,
.no-padd .col-sm-11,
.no-padd .col-md-11,
.no-padd .col-lg-11,
.no-padd .col-xs-12,
.no-padd .col-sm-12,
.no-padd .col-md-12,
.no-padd .col-lg-12 {
  padding: 0;
}
header {
  background: white;
  position: fixed;
  width: 100%;
  z-index: 5000;
  top: 0;
  height: 70px;
  box-shadow: grey 0px -3px 9px 1px;
}
@media (max-width: 767px) {
  header {
    position: relative;
    height: auto;
  }
}
header .logo {
  padding: 8px 0;
}
@media (max-width: 991px) {
  header .logo {
    padding: 12px 0;
  }
}
header .logo img {
  max-width: 70px;
}
@media (max-width: 991px) {
  header .logo img {
    max-width: 60px;
  }
}
@media (max-width: 767px) {
  header .logo {
    text-align: center;
  }
}
header .mobile_menu {
  position: relative;
  bottom: -1px;
}
header .mobile_menu button {
  background: #f9593a;
  outline: none;
  width: 100%;
  border: 0;
  color: white;
  position: relative;
  padding: .8em;
}
header .mobile_menu button span {
  position: absolute;
  right: 8px;
  top: 12px;
}
@media (max-width: 767px) {
  header .nav_menu {
    text-align: center;
    height: 0;
    overflow: hidden;
    transition: all .3s;
    margin: 0;
    width: 100%;
    background: white;
    bottom: -1px;
    position: relative;
  }
}
@media (max-width: 767px) {
  header .nav_menu ul {
    padding: 0 0 1em 0;
  }
}
header .nav_menu ul li {
  text-transform: uppercase;
}
header .nav_menu ul li a {
  color: #f9593a;
  font-weight: 600;
  font-size: .9em;
  letter-spacing: .16em;
  padding: 25px;
  position: relative;
}
header .nav_menu ul li a:hover {
  background: rgba(249, 89, 58, 0.1);
}
header .nav_menu ul li a:hover:after {
  width: 20%;
}
header .nav_menu ul li a:hover:before {
  width: 20%;
}
header .nav_menu ul li a:after {
  content: "";
  height: 1px;
  background: #f9593a;
  width: 0;
  left: 50%;
  position: absolute;
  bottom: 15px;
  transition: all .3s;
}
header .nav_menu ul li a:before {
  content: "";
  height: 1px;
  background: #f9593a;
  width: 0;
  right: 50%;
  position: absolute;
  bottom: 15px;
  transition: all .3s;
}
header.page-huevos-copita .mobile_menu button {
  background: #913a91;
}
header.page-huevos-copita .nav_menu ul li a {
  color: #913a91;
}
header.page-huevos-copita .nav_menu ul li a:hover {
  background: rgba(145, 58, 145, 0.1);
}
header.page-huevos-copita .nav_menu ul li a:after {
  background: #913a91;
}
header.page-huevos-copita .nav_menu ul li a:before {
  background: #913a91;
}
header.page-cerdo .mobile_menu button {
  background: #005fbd;
}
header.page-cerdo .nav_menu ul li a {
  color: #005fbd;
}
header.page-cerdo .nav_menu ul li a:hover {
  background: rgba(0, 95, 189, 0.1);
}
header.page-cerdo .nav_menu ul li a:after {
  background: #005fbd;
}
header.page-cerdo .nav_menu ul li a:before {
  background: #005fbd;
}
.banner_home .item .banner_content_cover {
  width: 100%;
  text-align: center;
  color: white;
  min-height: 600px;
}
.banner_home .item .banner_content_cover .banner_content_inn {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}
.banner_home .item .banner_content_cover .banner_content_inn h3 {
  font-family: 'Arvo', serif;
  font-size: 3.5em;
  font-weight: 600;
}
.banner_home .item .banner_content_cover .banner_content_inn h4 {
  text-transform: uppercase;
  font-size: .9em;
  letter-spacing: 0.15em;
  font-weight: 600;
}
.banner_home .item .banner_content_cover .banner_content_inn .banner_txt {
  margin-bottom: 20px;
}
.banner_home .item .banner_content_cover .banner_content_inn .btn {
  background: #f9593a;
  color: white;
  text-transform: uppercase;
  padding: 15px 40px;
  letter-spacing: 0.1em;
  border-radius: 25px;
}
.banner_home.copita .item .banner_content_cover .banner_content_inn {
  padding: 4em 1em 1em;
}
.banner_home.copita .item .banner_content_cover .btn {
  background: #913a91;
}
.banner_home.don-cerdo .item .banner_content_cover {
  color: #005fbd;
  text-align: left;
}
.banner_home.don-cerdo .item .banner_content_cover .banner_content_inn {
  margin: 0 0 0 10%;
  padding: 5em 0 1em 0;
  max-width: 480px;
}
@media (max-width: 767px) {
  .banner_home.don-cerdo .item .banner_content_cover .banner_content_inn {
    margin: 0;
    padding: 3em 1em;
  }
}
.banner_home.don-cerdo .item .banner_content_cover h3 {
  font-size: 5.5em;
  line-height: 1em;
}
@media (max-width: 767px) {
  .banner_home.don-cerdo .item .banner_content_cover h3 {
    font-size: 4em;
  }
}
.banner_home.don-cerdo .item .banner_content_cover .banner_txt {
  max-width: 436px;
  margin-top: 20px;
}
.banner_home.don-cerdo .item .banner_content_cover .btn {
  background: #ff3346;
}
.banner_home.distribucion .item .banner_content_cover .banner_content_inn {
  padding: 10em 1em 1em;
}
.banner_home.distribucion .item .banner_content_cover .btn {
  background: #913a91;
}
.content_page .content_page_inner {
  padding: 5em 0 1em 0;
  max-width: 1000px;
  margin: 0 auto;
}
.content_page .content_page_inner .cover_image {
  text-align: center;
  padding-top: 50px;
}
.content_page .content_page_inner h1 {
  border-bottom: 2px solid #ffc80a;
  font-family: 'Arvo', serif;
  font-size: 2.8em;
  padding-bottom: 20px;
  line-height: 1.1em;
  margin-bottom: 20px;
}
.content_page .content_page_inner .content_txt {
  color: #7e8082;
  text-align: left;
}
.content_page .content_page_inner .btn_conoce {
  display: block;
  margin: 2em auto 0 auto;
  color: #d6d6d6;
  text-align: center;
}
.content_page .content_page_inner .btn_conoce span {
  font-size: 1.5em;
}
.content_page .content_page_inner .btn_conoce:hover {
  text-decoration: none;
}
.content_page.copita .content_page_inner {
  padding: 5em 0;
}
.content_page.copita .content_page_inner h1 {
  border: 0;
  font-size: 3.8em;
  padding-top: 20px;
}
.content_page.copita .content_page_inner .content_txt {
  padding: 1em 0;
}
.content_page.copita .content_page_inner .content_txt ul li {
  list-style: none;
  position: relative;
  margin-bottom: 5px;
}
.content_page.copita .content_page_inner .content_txt ul li:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #913a91;
}
.content_page.copita .content_page_inner .vr {
  position: absolute;
  width: 2px;
  height: 100%;
  background: #913a91;
  left: 40%;
  top: 0;
}
@media (max-width: 767px) {
  .content_page.copita .content_page_inner .vr {
    display: none;
  }
}
.content_page.copita .bg_content_page_img .btn_conoce {
  position: absolute;
  color: white;
  text-align: center;
  bottom: 6px;
  width: 100%;
  display: block;
}
.content_page.copita .bg_content_page_img .btn_conoce span {
  font-size: 1.5em;
}
.content_page.copita .bg_content_page_img .btn_conoce:hover {
  text-decoration: none;
}
.content_page.don-cerdo .content_page_inner {
  padding: 0;
  max-width: 100%;
}
.content_page.don-cerdo .content_page_inner h1 {
  border-color: #005fbd;
}
.content_page.don-cerdo .content_page_inner .content_txt {
  padding: 1em 0;
}
.content_page.don-cerdo .content_page_inner .content_txt ul li {
  list-style: none;
  position: relative;
  margin-bottom: 5px;
}
.content_page.don-cerdo .content_page_inner .content_txt ul li:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff3346;
}
.content_page.don-cerdo .content_page_inner .cover_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 767px) {
  .content_page.don-cerdo .content_page_inner .cover_image {
    display: none;
  }
}
.content_page.don-cerdo .content_page_inner .bg_content_page {
  max-width: 600px;
  padding: 2em;
}
@media (max-width: 767px) {
  .content_page.don-cerdo .content_page_inner .bg_content_page {
    max-width: 100%;
  }
}
.content_page.don-cerdo .content_page_inner .btn_conoce {
  position: relative;
  padding-left: 30px;
  text-align: left;
  display: inline-block;
}
.content_page.don-cerdo .content_page_inner .btn_conoce span {
  position: absolute;
  left: 0;
  top: 0;
}
.productos {
  background: #eaeaea;
}
.productos .head_productos {
  text-align: center;
  background: #ffc80a;
  padding: 5em 0;
}
.productos .head_productos h2 {
  font-family: 'Arvo', serif;
  font-size: 3.5em;
  line-height: 1.1em;
  padding-bottom: 0;
}
.productos .head_productos .subtitle {
  color: white;
  font-size: 1.4em;
}
.productos .container_productos {
  background: white;
  margin: 2em 0 4em 0;
}
.productos .container_productos .producto_item {
  text-align: center;
  padding: 20px;
  height: 280px;
  position: relative;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  transition: all .3s;
}
@media (max-width: 767px) {
  .productos .container_productos .producto_item {
    height: auto;
  }
}
.productos .container_productos .producto_item .content_producto {
  position: relative;
  z-index: 2;
}
.productos .container_productos .producto_item .content_producto h3 {
  text-transform: uppercase;
  font-size: .9em;
  font-weight: bold;
  padding-bottom: 0;
  letter-spacing: 0.1em;
  margin-top: 0;
  height: 26px;
  overflow: hidden;
}
.productos .container_productos .producto_item .content_producto img {
  max-width: 80%;
  transition: all .3s;
}
.productos .container_productos .producto_item .content_producto .producto_txt {
  color: #7e8082;
  font-size: .9em;
  line-height: 1.2em;
  margin-top: 20px;
}
.productos .container_productos .producto_item:after {
  content: " ";
  position: absolute;
  top: -3%;
  left: -3%;
  width: 106%;
  height: 106%;
  z-index: 1;
  background: #f9593a;
  opacity: 0;
}
.productos .container_productos .producto_item:hover {
  padding-top: 15px;
}
.productos .container_productos .producto_item:hover h3 {
  color: white;
  font-size: 1.1em;
  height: 32px;
}
.productos .container_productos .producto_item:hover .producto_txt {
  color: white;
}
.productos .container_productos .producto_item:hover img {
  max-width: 90%;
}
.productos .container_productos .producto_item:hover:after {
  opacity: 1;
}
.productos.copita .head_productos {
  background: #913a91;
}
.productos.copita .head_productos h2 {
  color: white;
}
.productos.copita .container_productos .producto_item:after {
  background: #f38034;
}
.productos.don-cerdo .head_productos {
  background: #005fbd;
}
.productos.don-cerdo .head_productos h2 {
  color: white;
}
.productos.don-cerdo .container_productos .producto_item:after {
  background: #ff3346;
}
footer {
  background: #9a9a9a;
  color: white;
}
footer a {
  color: white;
}
footer .rrss {
  list-style: none;
  float: right;
}
@media (max-width: 767px) {
  footer .rrss {
    float: none;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}
footer .rrss li {
  display: inline;
}
footer .rrss li a {
  padding: 25px 10px;
  display: inline-block;
}
footer .logo {
  padding: 6px 0;
}
footer .logo img {
  max-height: 58px;
}
@media (max-width: 767px) {
  footer .logo {
    text-align: center;
  }
}
footer .menu_foot {
  margin: 4em 0 2em 0;
}
footer .nav_menu {
  float: right;
}
@media (max-width: 767px) {
  footer .nav_menu {
    text-align: center;
    transition: all .3s;
    margin: 0;
    width: 100%;
    float: none;
  }
}
@media (max-width: 767px) {
  footer .nav_menu ul {
    padding: 0 0 1em 0;
  }
}
footer .nav_menu ul li {
  text-transform: uppercase;
}
footer .nav_menu ul li a {
  font-weight: 600;
  font-size: .9em;
  letter-spacing: .16em;
  padding: 25px;
  position: relative;
  color: white;
}
footer .nav_menu ul li a:hover {
  background: none;
}
footer .copyright {
  text-align: center;
  font-size: .8em;
  padding: 3em 0;
  border-top: 1px solid white;
}
.puntos-distribucion {
  background: #f9593a;
  color: white;
}
.puntos-distribucion .content_page_inner {
  padding: 3em 0;
}
.puntos-distribucion .content_page_inner h1 {
  font-family: 'Arvo', serif;
  font-size: 2.8em;
  line-height: 1.1em;
  font-weight: 600;
  margin-right: 20px;
  text-align: right;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .puntos-distribucion .content_page_inner h1 {
    text-align: left;
    margin-right: 0;
  }
}
.puntos-distribucion .content_page_inner .content_txt {
  padding-left: 20px;
  max-width: 500px;
}
@media (max-width: 767px) {
  .puntos-distribucion .content_page_inner .content_txt {
    padding-left: 0;
  }
}
.puntos-distribucion .content_page_inner .content_txt h4 {
  font-weight: 600;
}
.puntos-distribucion .content_page_inner .vr {
  position: absolute;
  width: 2px;
  height: 100%;
  background: white;
  left: 50%;
  top: 0;
  margin-left: -1px;
}
@media (max-width: 767px) {
  .puntos-distribucion .content_page_inner .vr {
    display: none;
  }
}
.puntos-distribucion .content_page_inner .puntos_cover {
  padding: 30px 30px 0;
  text-align: center;
}
@media (max-width: 767px) {
  .puntos-distribucion .content_page_inner .puntos_cover {
    height: 0;
    overflow: hidden;
    padding-top: 10px;
  }
}
.puntos-distribucion .content_page_inner .puntos_cover .punto_item {
  display: inline-block;
  margin: 5px 10px;
}
.puntos-distribucion .content_page_inner .puntos_cover .punto_item button {
  background: white;
  display: block;
  color: #f9593a;
  border-radius: 20px;
  width: 200px;
  font-family: 'Arvo', serif;
}
.puntos-distribucion .content_page_inner .cbo_puntos {
  display: none;
}
@media (max-width: 767px) {
  .puntos-distribucion .content_page_inner .cbo_puntos {
    display: block;
  }
}
.puntos-distribucion .cover_map #map {
  width: 100%;
  height: 400px;
}
.puntos-distribucion .cover_map .content_market_inner h5 {
  color: #212121;
}
.puntos-distribucion .cover_map .content_market_inner .descripcion_market {
  color: #f9593a;
}
.puntos-distribucion .cover_map .content_market_inner .descripcion_market .btn {
  background: #f9593a;
  color: white;
  text-transform: uppercase;
  padding: 8px 40px;
  letter-spacing: 0.1em;
  border-radius: 25px;
}
.contact {
  background: #ffb200;
  color: white;
  padding: 1em 0;
}
.contact .form_container {
  max-width: 800px;
  margin: 0 auto;
}
.contact .form_container h2 {
  font-family: 'Arvo', serif;
  font-size: 2.8em;
  line-height: 1.1em;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.contact .form_container form input {
  border-radius: 0;
}
.contact .form_container form textarea {
  border-radius: 0;
  height: 83px;
}
.contact .form_container form .send_form input {
  background: #f9593a;
  color: white;
  text-transform: uppercase;
  padding: 15px 40px;
  letter-spacing: 0.1em;
  border-radius: 25px;
  border: 0;
  margin: 2em auto 0;
  display: block;
  width: 200px;
}

/*# sourceMappingURL=app.css.map */
